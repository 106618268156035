import Projects from "components/Projects";
import Hero from "components/Hero";
import NavBar from "components/NavBar";
import Footer from "components/Footer";

const Landing = () => {
  return (
    <>
      <Hero />
      <NavBar />
      <Projects />
      <Footer />
    </>
  );
};

export default Landing;
