import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

const useStyles = (theme) => ({
  root: {
    height: `calc(100vh - ${theme.appBarHeight}px)`,
    width: "100vw",
    backgroundColor: theme.palette.common.white,
  },
});

const Hero = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  
  return (

  <Grid
  container
  style={styles.root}
  alignItems="center"
  justifyContent="center"
  direction="column"
>
  <Grid item>
    <Typography variant="h1" fontWeight={500}>Jacob Dayton</Typography>
  </Grid>
  <Grid item>
    <Typography variant="h5" fontWeight={600}>Developer</Typography>
  </Grid>
</Grid>
)}

export default Hero;
