// VIEWS
import Landing from 'views/Landing';
import About from 'views/About';
import DefaultLayout from 'layouts/DefaultLayout';

const routes = [
  {
    path: '/',
    component: Landing,
    exact: true,
  },
  {
    path: '/about',
    component: About,
    exact: true,
    Layout: DefaultLayout,
  },
];

export default routes;
