import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
});

const Footer = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <footer style={styles.root}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <h4>Contact Me!</h4>
        </Grid>
          <p>Email: JacobTDayton@gmail.com</p>
        <Grid item>
          <p>© 2024 Jacob Dayton</p>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
